@import "../../../../../assets/scss/shared/index";

.attach-tests {
  width: 460px;
  max-width: 100%;
}

.attach-tests__panel {
  display: flex;
  justify-content: space-between;

  @include xs {
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: center;
  }
}

.attach-tests__label {
  margin: 30px 0 10px;

  --el-fonts--text-color: #{$label-color};
}

.attach-tests__block {
  background-color: $events-color;
  border-radius: $block-radius;
  border: 1px solid transparent;
  padding: 15px;
  margin-bottom: 20px;
  cursor: pointer;
}

.attach-tests__block--active {
  border-color: $brand-color;
}

.attach-tests__block-name {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.attach-tests__block-icon {
  margin-right: 10px;
}

.attach-tests__desc {
  --el-fonts--text-color: #{$label-color};
}
