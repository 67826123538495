@import '../../assets/scss/shared/index';

[class*='square--'] {
  background-color: $icon-bg-color;
  border: $border-style;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(.square--auto) {
    cursor: pointer;
  }

  &.square--off {
    filter: grayscale(1);
    opacity: 0.6;
    pointer-events: none;
  }

  &.square--hover {
    transition: $move-ease;

    &:hover {
      --el-general--layout-color: #{$button-hover-color};
      --el-fonts--icon-color: #{$white-color};
    }

    &:active {
      --el-general--layout-color: #{$button-active-color};
      --el-fonts--icon-color: #{$text-color};
    }
  }

  &.square--hover-dark {
    transition: $move-ease;

    --el-fonts--icon-color: #{$brand-color};

    &:hover {
      --el-general--layout-color: #{$button-hover-color};
      --el-general--brand-color: #{$text-color};
    }

    &:active {
      --el-general--layout-color: #{$button-active-color};
    }
  }

  &.square--transparent {
    transition: $move-ease;

    --el-general--layout-color: transparent;

    box-shadow: none;

    svg,
    svg path,
    svg rect {
      transition: $move-ease;
    }

    &:hover {
      --el-general--layout-color: #{$white-color};

      box-shadow: $box-shadow-big;
    }

    &:active {
      --el-fonts--icon-color: #{$text-color};
    }
  }
}

.square--play {
  --el-general--layout-color: #{$button-hover-color};
  --el-general--brand-color: #{$text-color};
  --el-fonts--icon-color: #{$text-color};

  svg,
  svg path,
  svg rect {
    transition: $move-ease;
  }
}

.square--text {
  min-width: 44px;
  height: 44px;
  border-radius: 6px;
  padding: 5px 20px;
}

.square--lg {
  min-width: 44px;
  min-height: 44px;
  height: 44px;
  border-radius: 6px;
  padding: 5px;
}

.square--md {
  min-width: 38px;
  height: 38px;
  border-radius: 6px;
  padding: 5px;
}

.square--sm {
  min-width: 28px;
  height: 28px;
  border-radius: 4px;
  padding: 5px;
}

.square--xs {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 3px;
}

.square--invert {
  --el-general--accent-color: #{$action-color};
}

.square--user {
  border-radius: 10px;
  box-shadow: $box-shadow-medium;
}

.square--stats {
  border-radius: 10px;
  box-shadow: none;

  --el-general--layout-color: #{$events-color};
}

.square--revert {
  --el-general--accent-color: #{$white-color};
}

.square--monochrome {
  transition: $move-ease;

  svg,
  svg path,
  svg rect {
    transition: $move-ease;
  }

  --el-fonts--icon-color: #{$label-color};
  --el-general--line-color: #{$monochrome-color};

  &:hover {
    --el-accent--monochrome-color: #{$button-hover-color};
  }

  &:active {
    --el-accent--monochrome-color: #{$button-active-color};
  }
}

.square--outlined {
  border: 2px solid $square-border-color;
  box-shadow: none;

  &:not(:hover, :active) {
    --el-general--layout-color: transparent;
  }
}

.square--collapse {
  svg {
    transform: rotate(180deg);
  }
}

.square--dark {
  --el-general--layout-color: #{$label-color};
  --el-fonts--icon-color: #{$white-color};
}
