@import '../../assets/scss/shared/index';
@import '../../../node_modules/react-datepicker/dist/react-datepicker.css';

.time-frame {
  color: $text-color;
  line-height: 38px;
  font-size: 14px;

  .react-datepicker {
    border: 1px solid $line-color;
    background-color: $layout-color;
    box-shadow: $box-shadow-big;

    @include xs {
      display: flex;
    }
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: $brand-color;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__year-text--in-selecting-range {
    background-color: $outline-color;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: $line-color;
  }

  .react-datepicker__header {
    background-color: transparent;
    border-color: $line-color;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: $layout-color;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: $line-color;
  }

  .react-datepicker-wrapper {
    flex: 1 1 #{'min(290px, calc(100% - 160px))'};

    @include less {
      width: 100%;
    }
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 20px;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
  }

  .react-datepicker__close-icon {
    padding-right: 10px;

    &::after {
      color: $white-color;
      font-size: 27px;
      background-color: $dashed-color;
      width: 21px;
      padding: 0;
      height: 21px;
      line-height: 21px;
      border-radius: 50%;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 40px;
    margin: 0;
    line-height: 38px;
    border-radius: 0;
  }

  .react-datepicker__time-list-item {
    line-height: 21px;
  }

  .react-datepicker__time-container {
    .react-datepicker__time {
      .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background-color: $outline-color;
      }
    }
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    &:not(:last-child) {
      border-right: 1px solid $line-color;
    }
  }

  .react-datepicker__week {
    &:not(:last-child) {
      border-bottom: 1px solid $line-color;
    }
  }

  .react-datepicker__month {
    border: 1px solid $line-color;
    margin: 15px 15px 10px;

    @include xs {
      margin: 0;
      border: 0;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range {
    background-color: $outline-color;
    color: $white-color;
  }
}
