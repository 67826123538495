@import "../../assets/scss/shared/index";

.meet {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    flex: auto;
    height: 100% !important;
  }
}
