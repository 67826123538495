@import "../../../assets/scss/shared/index";

.login {
  display: flex;
  align-items: center;
  justify-content: center;

  //min-height: calc(100vh - #{$home-menu-height});
  min-height: 100vh;

  @include animate(fade);
}

.login__data-link {
  margin-left: 10px;
}

.login__forgot {
  margin: 0 auto;
  display: inline-block;
  min-width: 200px;

  --el-fonts--text-color: #{$label-color};
}

.login__form {
  width: 460px;
  margin: 0 15px;

  a {
    @include font($accent-color 14px 21px 500);

    text-decoration: underline;
  }
}

.login__entry {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .toggle--check {
    flex: 1 1;
  }

  a {
    width: 100%;
    text-align: right;
    margin: 25px 0 4.5vmax;
  }
}

.login__description {
  opacity: .5;

  span {
    @include font($accent-color 16px 24px 500);
  }

  p {
    @include font($accent-color 12px 18px 500);

    margin-top: 7px;
  }
}

.login__data {
  > div {
    margin-bottom: 19px;
  }
}

.login__source {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  .text--xs {
    @include less(min) {
      margin: 16px 0 28px
    }
  }

  .text--lg {
    line-height: 17px;
  }

  .button--primary {
    width: calc(100% - 159px);
    margin: 0 auto 20px;

    @include xs {
      width: 100%;
    }

    @include less {
      margin: 0;
    }
  }

  @include less {
    grid-gap: 20px;
  }
}

.login__data-title {
  margin-bottom: 34px;
}

.login__source-forgot {
  margin-left: auto;

  &:hover {
    --el-fonts--text-color: #{$outline-color};
  }
}

.login__btn {
  margin: 10px auto;
}

.login__source-desc {
  margin: 20px 0;
}

.login__icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
