@import "../../../../assets/scss/shared";

.question-attach {
  margin: 38px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 40px 60px;
}

.question-attach__control-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
}

.question-attach__control-body {
  border: 1px solid #C6CFE1;
  border-radius: 6px;
  line-height: 53px;
  padding: 0 55px 0 15px;
  position: relative;
  display: flex;
}

.question-attach__control-text {
  max-width: calc(100% - 10px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.question-attach__control-switch {
  position: absolute;
  top: 7px;
  right: 10px;

  --el-fonts--icon-color: #{$brand-color};
}

.question-attach__control-preview {
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 9px;
  line-height: initial;
}

.question-attach__close {
  position: absolute;
  right: 10px;
  top: 10px;

  --el-fonts--icon-color: #{$label-color};
}

.question-attach__control-dots {
  padding: 16px 10px;
  position: absolute;
  right: -10px;
  top: 0;
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$brand-color};
  }
}

.question-attach__control-menu {
  position: absolute;
  top: 34px;
  right: 0;
  z-index: 1;
}
