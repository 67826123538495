@import "../../assets/scss/shared/index";

.t--panel__back {
  @include font($text-color 17px 41px 600);

  letter-spacing: 0.41px;
  display: flex;
  align-items: center;
  cursor: pointer;

  --el-fonts--icon-color: #{$outline-color};

  @include sm {
    display: none;
  }

  svg {
    margin-right: $source-offset;
  }
}

.t--panel__tour {
  display: flex;
  align-items: center;

  i {
    background-color: $accent-color;
    padding: 8px;
    border-radius: 50%;

    --el-fonts--icon-color: #{$white-color};
  }

  > div {
    min-width: 190px;
    margin-left: 30px;
  }
}

.t--panel__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: $t-panel-head-pg;
  min-height: $t-panel-head-height;

  .admin-panel {
    margin: auto;
  }
}

.t--panel__foot {
  padding: 0 $source-offset 12px
}
