@import "../../../../assets/scss/shared";

.heading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 6px;
  left: -16px;
  max-width: calc(100% - 120px);

  --el-general--line-color: #{$outline-color};
  --el-fonts--label-color: #{$outline-color};

  .text--eject {
    display: flex;
    align-items: center;
    width: 100%;
  }

  strong {
    min-width: 18px;
    text-align: center;
    display: inline-block;
  }

  &:not(:hover) {
    span, h1, h2, h3, h4, h5, h6 {
      font-size: 0;
    }
  }
}

.heading__text {
  span, h1, h2, h3, h4, h5, h6 {
    transition: all .1s ease;

    @include textTruncate(1);
  }
}
