@import "../../assets/scss/shared/index";
@import '../../../node_modules/simplebar/dist/simplebar.min.css';

.scroll {
  .simplebar-horizontal {
    opacity: 0;
  }
}

.simplebar-scrollbar::before {
  background-color: $icon-color;
}

.scroll__mobile {
  overflow-y: scroll;
}

.simplebar-vertical {
  @include sm {
    opacity: 0;
  }
}
