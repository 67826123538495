@import "../../../assets/scss/shared";

.progress-bar__container {
  height: 55px;
  min-width: 600px;
  overflow: hidden;
  position: relative;
  padding: 55px 0 45px;
  margin-top: -15px;
}

.progress-bar__filler {
  height: 12px;
  width: 50%;
  background-color: $outline-color;
  border-radius: 50px;
  text-align: right;
  transition: all ease 0.5s;
  position: relative;
}

.progress-bar__background {
  background: #F5F9FD;
  box-shadow: 0 8px 10px 1px rgb(107 142 177 / 10%);
  border-radius: 6px;
}

.progress-bar__upload {
  height: 40px;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 700;
  color: #B0B7CB;

  &:not(.progress-bar__upload-active) {
    &::after {
      content: "...";
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis-dot 2s infinite .3s;
      width: 1.25em;
    }
  }
}

.progress-bar__upload-active {
  height: 40px;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 700;
  color: $black-color
}

.modal-container {
  max-height: 155px;
}

@keyframes ellipsis-dot {
  25% {
    content: "";
  }

  50% {
    content: ".";
  }

  75% {
    content: "..";
  }

  100% {
    content: "...";
  }
}

.progress-bar__content {
  display: flex;
  justify-content: space-between;
}

.progress-bar__percent {
  height: 40px;
  font-size: 16px;
  margin-top: 15px;
  font-weight: 700;
  color: #B0B7CB;
}

.progress-bar__percent-active {
  color: $black-color
}

.progress-bar__close {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
  display: block !important;
}

.progress-bar__retry {
  text-align: center;
  font-size: 16px;
  color: #586DEB;
  font-weight: 700;
  margin-left: 5px;
  cursor: pointer;
}

.progress-bar__dust {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 54px;
  bottom: 14px;

  path {
    &:nth-child(1) {
      animation: fadeIn 1.6s linear .3s infinite;
    }

    &:nth-child(2) {
      animation: fadeIn 1.6s linear .6s infinite;
    }

    &:nth-child(3) {
      animation: fadeIn 1.6s linear .9s infinite;
    }
  }
}

.progress-bar__bicycle {
  width: 60px;
  height: 40px;
  position: absolute;
  bottom: calc(100% + 1px);
  right: 0;
  transform-origin: 100% 100%;
}

.progress-bar__bicycle--active {
  animation: stopBicycle .5s ease-in-out both;
}
