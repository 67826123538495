@import "../../../assets/scss/shared";

.confirm {
  background-color: $white-color;
  border-radius: $block-radius;
  box-shadow: $shadow;
  padding: 42px 30px;
  text-align: center;
  width: 781px;
  position: relative;
  margin: -50px;

  h1 {
    margin: 50px 0 30px;
  }

  .text--md {
    margin-bottom: 6px;
  }

  .button--primary {
    min-width: 190px;
    margin: 0 $default-offset
  }

  > div {
    &:not(:first-child) {
      margin-top: calc(#{$source-offset} * 2);

    }
  }
}

.confirm__icon {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: $accent-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  --el-fonts--icon-color: #{$white-color};

  svg {
    width: 30px;
    height: 30px;
  }
}

.confirm__close {
  position: absolute;
  top: 14px;
  right: 13px;
  cursor: pointer;

  --el-fonts--icon-color: #{$accent-color};
}
