@import "../../assets/scss/shared/index";

.t--question {
  text-align: left;
  padding: 0 29px 10px;

  @include sm {
    padding: 0 0 10px;
  }
}

.t--question__head {
  .question-upper {
    margin-top: 23px;
  }
}

.t--question__choice {
  margin: 43px 0 17px;
  max-width: 690px;
  display: flex;
  align-items: center;

  @include sm {
    margin: 20px 0 0;
    flex-wrap: wrap;
  }

  //--el-fonts--text-color: #{$label-color};
  > div {
    margin: 0 52px 16px 0;
  }

  .scores--eject {
    @include xs(min) {
      position: absolute;
      right: 0;
    }

    @include xs {
      flex: auto;
      margin: 0 0 16px;
    }
  }
}

.t--question__free {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: 27px 0 0;
  max-width: 720px;

  @include xs(min) {
    grid-gap: 20px;
  }

  > div {
    margin-bottom: 16px;
  }
}
