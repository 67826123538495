@mixin mobileNavigation() {
  position: fixed;
  right: 100%;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background-color: $accent-color;
  padding: 15px;
  box-sizing: border-box;
  height: calc(100% - #{$home-menu-height});
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transition: $move-ease;

  &.active {
    transform: translateX(100%);
  }
}

@mixin buttonToggle() {
  @content;

  position: relative;
  right: 0;
  width: 40px;
  height: 40px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;

  &::before, &::after {
    content: "  ";
    background-color: $white-color;
    width: 100%;
    height: 4px;
    position: absolute;
    transition: $move-ease;
    border-radius: 10px;
    left: 0;
  }

  &::before {
    top: 12px;
  }

  &::after {
    bottom: 12px;
  }

  &:hover, &.active {
    &::before {
      transform: translate3d(0, 5px, 0) rotate(45deg);
    }

    &::after {
      transform: translate3d(0, -7px, 0) rotate(-45deg);
    }
  }
}

@mixin mobile() {
  @content;

  position: relative;
  right: 0;
  min-width: 46px;
  height: 46px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;

  &::before, &::after {
    content: "  ";
    background-color: $white-color;
    width: 100%;
    height: 6px;
    position: absolute;
    transition: $move-ease;
    left: 0;
    border-radius: $block-radius;
  }

  &::before {
    top: 8px;
  }

  &::after {
    bottom: 8px;
  }

  &.active {
    &::before {
      transform: translate3d(0, 9px, 0) rotate(45deg);
    }

    &::after {
      transform: translate3d(0, -15px, 0) rotate(-45deg);
    }

    span {
      opacity: 0;
    }
  }

  span {
    background-color: $brand-color;
    width: 100%;
    height: 6px;
    position: absolute;
    transition: $move-ease;
    top: 20px;
    left: 0;
  }
}
