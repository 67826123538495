@import "../../../assets/scss/shared/index";

.modal-meet {
  max-width: 100%;
  width: 1200px;
  height: #{'min(80vh, 700px)'};
  display: flex;
  flex-direction: column;
}

.modal-meet__head {
  margin: 4px 0 32px;
}

.modal-meet__body {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: $block-radius;
  overflow: hidden;
}

.modal-meet__stream {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
