@import '../../assets/scss/shared/index';

[class*='button--'] {
  text-align: center;
  border-radius: $block-radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $move-ease;
  background-color: $button-color;
  border: 1px solid;

  --el-fonts--icon-color: #{$title-color};

  &:focus {
    outline: none;
  }

  &:not([class*='button--w']) {
    padding: 0 $default-offset;
  }

  &:not(.button--text, .button--create) {
    &:hover {
      --el-ui--button-color: #{$button-hover-color};
    }

    &:active {
      --el-ui--button-color: #{$button-active-color};

      border-color: $button-border-active-color;
    }
  }

  &.button--b {
    font-weight: 700;
  }

  &.button--off {
    &:not(.button--monochrome) {
      --el-ui--button-color: #{$monochrome-color};
    }

    --el-fonts--text-color: #{$white-color};
    --el-ui--button-text-color: #{$white-color};

    pointer-events: none;
  }

  &.button--monochrome {
    &:not(:hover, :active) {
      --el-ui--button-active-color: #{$label-color};
      --el-general--brand-color: #{$label-color};
    }
  }

  &:not(.button--b) {
    font-weight: 500;
  }

  svg path,
  svg circle {
    transition: $move-ease;
  }
}

.button__loader {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: fade 0.3s ease, scalePartUp 0.3s ease both;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid;
    border-right-color: transparent;
    animation: circle 0.5s linear infinite;
  }
}

.button--user {
  border-radius: 10px;
}

.button--lg,
.button--md {
  font-size: 16px;
}

.button--lg {
  line-height: 53px;

  &:not(.button--auto) {
    min-width: 220px;
  }
}

.button--w-sm {
  width: 140px;
  min-width: 140px;
}

.button--w-md {
  width: 220px;
  min-width: 220px;
}

.button--md {
  line-height: 42px;

  &:not(.button--auto, .button--w-sm) {
    min-width: 220px;
  }
}

.button--sm {
  line-height: 36px;
  font-size: 14px;

  &:not(.button--auto) {
    min-width: 140px;
  }
}

.button--xs {
  line-height: 26px;
  font-size: 11px;

  &:not(.button--auto) {
    min-width: 100px;
  }
}

.button--primary {
  color: $button-text-color;
  border-color: $button-color;

  i {
    min-width: 21px;
    height: 21px;
    border-radius: 50%;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $button-text-color;

    --el-fonts--icon-color: #{$brand-color};
  }

  &.button--active {
    --el-general--accent-color: #{$brand-color};
  }
}

.button--secondary {
  color: $brand-color;
  border: 1px solid $button-color;
  box-shadow: $box-shadow-big;

  --el-ui--button-color: #{$white-color};
  --el-fonts--icon-color: #{$brand-color};

  &:hover,
  &.button--active {
    color: $text-color;

    --el-fonts--icon-color: #{$text-color};
  }

  svg {
    margin-right: 13px;
  }

  &.button--active {
    --el-ui--button-color: #{$brand-color};
  }
}

.button--text {
  color: $brand-color;

  --el-ui--button-color: transparent;

  border: 0;

  i {
    min-width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: $brand-color;
    margin-right: 11px;

    --el-fonts--icon-color: #{$white-color};

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.button--outlined {
  color: $brand-color;
  letter-spacing: 0.41px;
  border: 1px solid $button-active-color;

  --el-ui--button-color: transparent;
  --el-fonts--icon-color: #{$button-active-color};

  i {
    min-width: 21px;
    height: 21px;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover,
  &.button--active,
  &:active {
    border-color: transparent;

    --el-general--brand-color: #{$title-color};
    --el-fonts--icon-color: #{$text-color};
  }

  &.button--active {
    --el-general--brand-color: #{$text-color};
    --el-ui--button-color: #{$brand-color};
  }
}

.button--disabled {
  color: $brand-color;
  letter-spacing: 0.41px;
  border-color: $monochrome-color;
  opacity: 0.8;

  --el-ui--button-color: #{$monochrome-color};
  --el-ui--button-active-color: #{$monochrome-color};
  --el-general--brand-color: #{$white-color};

  i,
  svg {
    display: none;
  }

  &:hover,
  &.button--active,
  &:active {
    border-color: transparent;
  }
}

.button--create {
  font-weight: 700;
  margin: 0 15px;
  border: 0;
  position: relative;

  --el-ui--button-color: transparent;
  --el-fonts--text-color: #{$label-color};

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 2px dashed $label-color;
    pointer-events: none;
    width: 100vw;
    opacity: 0.4;
    max-width: 450px;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }

  i {
    background-color: $label-color;
    padding: 4px;
    box-sizing: content-box;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 31px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    transition: $move-ease;

    --el-fonts--icon-color: #{$white-color};
  }
}

.button--compact {
  font-size: 0;

  i {
    margin: 0;
  }
}

.button--mobile {
  @include md {
    @include mobile;
  }
}

.button--loading {
  position: relative;
  font-size: 0;
}
