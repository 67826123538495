@import "../../assets/scss/shared/index";

.bdn--question {
  max-width: 1200px;
  margin: 0 auto 30px;

  @include sm(min) {
    padding: 0 10px;
  }
}

.bdn--question__item {
  &:not(.active, :hover) {
    .handle--question {
      opacity: 0;
    }
  }
}

.bdn--question__area-outlined {
  //padding: $source-offset 10px;
  //border: 2px dashed transparent;
  &.active {
    //border-color: $action-color;
    .bdn--question__item {
      &:not(.active) {
        pointer-events: none;
      }
    }

    .question {
      pointer-events: none;
    }
  }
}

.bdn--answer {
  margin: 0 0 10px -30px;
  width: calc(100% + 60px);

  @include sm {
    margin: 0 0 10px -30px;
    width: calc(100% + 30px);
  }
}

.bdn--answer__area-outlined {
  &.active {
    .bdn--answer__item {
      &:not(.active, :hover) {
        pointer-events: none;
      }
    }
  }
}

.bdn--answer__item {
  //.active & {
  //  &:not(.active):not(:hover) {
  //    .handle--question {
  //      opacity: 0;
  //    }
  //  }
  //}
}

//.bdn--event {
//  margin-top: -22px;
//}
.bdn--event__area {
  position: relative;
  padding: $source-offset 0;

  &.active {
    opacity: .5;
  }
}

.bdn--event__area-outlined {
  //&.active {
  //  outline: 2px dashed red;
  //  background-color: #f000ff29;
  //}

  @include xs {
    position: relative; //TODO
    //z-index: 0;
  }
}

//
//.bdn--event__item {
//  z-index: 22222;
//
//  //padding-left: 10px;
//  &.active {
//    background-color: #00ff0029
//  }
//}
