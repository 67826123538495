@import '../../../../assets/scss/shared';

.theme {
  width: #{'min(700px, calc(100vw - 30px))'};
  position: relative;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 33px;
    text-align: center;
  }

  .text--lg {
    margin-bottom: 7px;
  }
}

.theme__data-colors,
.theme__data-layouts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto 10px;
  background-color: $events-color;
  padding: 20px 10px;
  border-radius: $block-radius;
}

[class*='theme__data-color--'] {
  width: #{'min(10vh, 45px)'};
  height: #{'min(10vh, 45px)'};
  border-radius: 50%;
  background-color: #f0f0f0;
  margin: 0 10px;
  cursor: pointer;
  border: 7px solid $layout-color;
  box-shadow: $box-shadow-smallest;
}

.theme__data-color--Blue {
  color: $colorBlue;
  background-color: $colorBlue;
}

.theme__data-color--Edbee {
  color: $colorEdbee;
  background-color: $colorEdbee;
}

.theme__data-color--Pink {
  color: $colorPink;
  background-color: $colorPink;
}

.theme__data-color--Purple {
  color: $colorPurple;
  background-color: $colorPurple;
}

.theme__data-color--Orange {
  color: $colorOrange;
  background-color: $colorOrange;
}

.theme__data-color--Green {
  color: $colorGreen;
  background-color: $colorGreen;
}

.theme__data-color--Custom {
  color: $black-color;
  background: linear-gradient(217deg, rgb(255 0 0 / 80%), rgb(255 0 0 / 0%) 70.71%),
    linear-gradient(127deg, rgb(0 255 0 / 80%), rgb(0 255 0 / 0%) 70.71%),
    linear-gradient(336deg, rgb(0 0 255 / 80%), rgb(0 0 255 / 0%) 70.71%);
}

[class*='theme__data-layout--'] {
  width: 160px;
  height: 63px;
  border: $border-style;
  display: flex;
  align-items: center;
  justify-content: center;
}

.theme__data-layout {
  position: relative;
}

.theme__data-check svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.theme__data-layout--none {
  pointer-events: none;
  opacity: 0.5;
}
