@import "../../../../assets/scss/shared/index";

.homework-confirm {
  text-align: center;
  width: 300px;
}

.homework-confirm__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
