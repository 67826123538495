@import "../../assets/scss/shared/index";

.die {
  position: relative;
  z-index: 0;
  opacity: .5;
}

.die__polygon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
