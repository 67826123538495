@import '../../assets/scss/shared/index';

.alphabet {
  position: absolute;
  width: 250px;
  padding: 16px 20px;
  border-radius: $block-radius;
  background-color: $layout-color;
  box-shadow: $box-shadow-big-short;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 17px 15px;
  right: -44px;
  top: 10px;
}

.alphabet__name {
  cursor: pointer;
  text-align: center;
}

.alphabet__name--active {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    background-color: $brand-color;
    border-radius: 4px;
    width: 22px;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
