@import "../../assets/scss/shared/index";

.breadcrumbs {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 32px;
  min-height: 18px;

  @include sm {
    margin-top: 15px;
    margin-bottom: 40px;

    --el-fonts--label-color: #{$text-color};
    --el-fonts--icon-color: #{$text-color};
  }
}

.breadcrumbs__link, .breadcrumbs__current {
  color: $label-color;
  letter-spacing: 0.41px;

  @include sm {
    font-size: 16px;
  }
}

.breadcrumbs__link {
  &:hover {
    --el-fonts--label-color: #{$outline-color};
  }

  &::after {
    content: '/';
    display: inline-block;
    margin: 0 4px;
    color: $icon-color;
  }
}
