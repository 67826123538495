@include fontFace('../../../fonts/montserrat/regular', 'Montserrat-Regular', 'Montserrat', 400);
@include fontFace('../../../fonts/montserrat/medium', 'Montserrat-Medium', 'Montserrat', 500);
@include fontFace('../../../fonts/montserrat/semi', 'Montserrat-SemiBold', 'Montserrat', 600);
@include fontFace('../../../fonts/montserrat/bold', 'Montserrat-Bold', 'Montserrat', 700);

@include typography {
  html {
    background-color: $bg-color;
  }

  body {
    @include font(inherit 0 1 400) {
      font-family: $family;
      word-break: break-word;
    }
  }

  a {
    text-decoration: none;
    outline: 0;

    &:not([class]) {
      color: $brand-color;
      transition: $move-ease;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: $title-color;
    font-weight: 700;
  }

  h1 {
    font-size: 26px;
    line-height: 32.5px;

    @include xs {
      font-size: 22px;
    }

    @include less {
      font-size: 18px;
      line-height: 27.5px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 30px;
  }

  h3 {
    font-size: 14px;
  }

  h4, h5, h6 {
    font-size: 12px;
  }

  b, strong, em {
    font-weight: 700;
  }

  button {
    transform: $move-ease;
    cursor: pointer;
  }

  em {
    font-style: italic;
  }

  input[type="checkbox"], input[type="radio"] {
    display: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  input[type="range"] {
    appearance: none;
    overflow: hidden;
  }

  input[type="range"]:focus {
    outline: none;
  }

  .loader {
    position: fixed;
    top: 0;
    z-index: 3;
    height: 4px;
    background-color: $outline-color;
    box-shadow: 0 0 8px $outline-color;
  }

  .t--center {
    text-align: center;
  }

  [class$='--hint']:not(.icon--hint) {
    position: relative;
    padding-right: 25px;
    box-sizing: content-box;

    &:not(:hover) {
      .icon--hint {
        opacity: 0;
      }
    }

    .icon--hint {
      position: absolute;
      right: 0;
    }
  }

  .wrs_image {
    max-height: initial;
  }
}
