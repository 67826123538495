@import "../../../../assets/scss/shared/index";

.library-tests {
  height: #{'min(798px, calc(80vh - 60px))'};
  width: 1020px;
  max-width: 100%;
}

.library-tests__title {
  padding-bottom: 10px;
}
