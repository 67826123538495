@import '../../assets/scss/shared/index';

.contour {
  min-width: 120px;
  border-radius: $block-radius;
  border: 1px solid transparent;
  text-align: center;
  line-height: 30px;
}

.contour--inProgress {
  background-color: $contour-in-progress-bg-color;
  border-color: $contour-in-progress-border-color;
}

.contour--notYet {
  background-color: $monochrome-color;
  border-color: $border-monochrom-color;
}

.contour--completed {
  background-color: $correct-color;
  color: $success-color;
  border-color: #d3f4af;
}

.contour--s-status {
  width: 140px;
  justify-content: center;
  border-radius: $block-radius;
  color: $text-color;
  align-self: center;
}

.contour--pending {
  background-color: $contour-pending-bg-color;

  --el-fonts--text-color: #{$outline-color};
}

.contour--rejected {
  background-color: $contour-rejected-bg-color;

  --el-fonts--text-color: #{$error-color};
}

.contour--accepted {
  background-color: $contour-accepted-bg-color;

  --el-fonts--text-color: #{$contour-accepted-color};
}
