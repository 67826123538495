html:not([data-environment]),
html[data-environment*='Base'] {
  @include map($base-theme, 'light');
  @include map($themes-color, 'colorEdbee');
  @include map($base-root, 'desktop');

  @include lg {
    @include map($base-root, 'lg');
  }

  @include md {
    @include map($base-root, 'md');
  }

  @include sm {
    @include map($base-root, 'sm');
  }

  @include xs {
    @include map($base-root, 'xs');
  }

  .event-settings__status--stopped {
    background-color: $success-color;

    --el-fonts--text-color: #{$white-color};
    --el-fonts--icon-color: #{$white-color};
  }

  .event-settings__status--working {
    background-color: $error-color;

    --el-fonts--text-color: #{$white-color};
    --el-fonts--icon-color: #{$white-color};
  }

  .question-part__head {
    background: $builder-head-bg-color;
    padding: 19px 30px 17px;
    border-radius: 6px 6px 0 0;
    text-align: left;

    --el-fonts--text-color: #{$white-color};
  }

  .question-preview:hover {
    --el-fonts--icon-color: #{$white-color};
    --el-fonts--text-color: #{$white-color};
  }
}

html[data-environment*='Pochet'] {
  @include map($pochet-theme, 'light');
  @include map($themes-color, 'colorPochet');
  @include map($pochet-root, 'desktop');

  @include lg {
    @include map($pochet-root, 'lg');
  }

  @include md {
    @include map($pochet-root, 'md');
  }

  @include sm {
    @include map($pochet-root, 'sm');
  }

  @include xs {
    @include map($pochet-root, 'xs');
  }

  .event-settings__status--stopped {
    background-color: $button-success-bg--color;
    border: 1px solid $button-success-border--color;

    --el-fonts--text-color: #{$success-color};
    --el-fonts--icon-color: #{$success-color};

    &:hover {
      background-color: $success-color;
      border: 1px solid $success-color;

      --el-fonts--text-color: #{$white-color};
      --el-fonts--icon-color: #{$white-color};
    }
  }

  .event-settings__status--working {
    background-color: $button-error-bg--color;
    border: 1px solid $button-error-border--color;

    --el-fonts--text-color: #{$error-color};
    --el-fonts--icon-color: #{$error-color};

    &:hover {
      background-color: $error-color;
      border: 1px solid $error-color;

      --el-fonts--text-color: #{$white-color};
      --el-fonts--icon-color: #{$white-color};
    }
  }

  .question-part__head {
    border: #{$border-style};
    max-height: 60px;
    background: #{$builder-head-bg-color};
    padding: 30px;
    border-radius: 8px;
    text-align: left;

    --el-fonts--text-color: #{$white-color};
  }

  .card__block-eye.square--eject {
    aspect-ratio: 1;
    margin: 0 0 0 15px;
    order: 1;
    border: 1px solid $card-block-color;
  }

  .card__thead-sort {
    &.square--active {
      --el-ui--square-border-color: #{$outline-color};
    }
  }
}

html[data-coloring*='Blue'] {
  @include map($themes-color, 'colorBlue');
}

html[data-coloring*='Purple'] {
  @include map($themes-color, 'colorPurple');
}

html[data-coloring*='Pink'] {
  @include map($themes-color, 'colorPink');
}

html[data-coloring*='Orange'] {
  @include map($themes-color, 'colorOrange');
}

html[data-coloring*='Green'] {
  @include map($themes-color, 'colorGreen');
}

@include reset;
@include declareAnimate($animations);

//@include debug();

//@include layer2();
