@import '../../../../assets/scss/shared';

.question-preview {
  display: flex;
  align-items: center;
  width: 200px;
  height: 80px;
  border-radius: $block-radius;
  background-color: $layout-color;
  box-shadow: $box-shadow-big;
  border: 1px solid transparent;

  //display: flex;
  //flex-direction: column;
  //align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 5px;

  --el-fonts--icon-color: #{$brand-color};

  &:hover {
    --el-general--layout-color: #{$hover-icon-bg-color};

    border: $border-style;

    path {
      //fill: $brand-color;
    }
  }

  i {
    margin-right: 15px;
  }

  div {
    line-height: 15px;
  }
}
