@import "../../assets/scss/shared/index";

.event-single__toggle {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  margin-left: 10px;
  color: $outline-color;
  cursor: pointer;

  &:hover {
    --el-layout--outline-color: #{$brand-color};
  }
}

.event-single-active {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 2px;
  color: $outline-color;
}

.event-single__data {
  display: flex;
  align-items: center;

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: $source-offset;

    strong {
      max-width: 90%;
    }

    div {
      white-space: nowrap;
      margin-top: 6px;
    }
  }
}

.event-single__data--active {
  position: relative;
}
