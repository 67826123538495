@import "../../../assets/scss/shared";

.intrasystem-search {
  position: relative;
  display: flex;
  flex-flow: row-reverse wrap;
  text-align: left;
  align-items: center;
}

.intrasystem-search__menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 30vw;
  z-index: 1;

  .content-menu__item {
    height: auto;
  }
}

.intrasystem-search__label {
  --el-fonts--text-color: #{$label-color}
}

.intrasystem-search__menu-option {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  flex-wrap: wrap;
  grid-gap: 20px;
  padding: 10px 0;
  width: 100%;
}

.intrasystem-search__menu-name {
  color: $label-color;
  font-size: 14px;
}
