@import "../../../../assets/scss/shared";

.assign-user {
  width: #{'min(700px, calc(100vw - 30px))'};
  position: relative;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 33px;
  }

  .text--lg {
    margin-bottom: 7px;
  }
}

.assign-user__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  --el-fonts--icon-color: #{$accent-color};
}

.assign-user__data {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 20px;
}

.assign-user__data-item {
  display: flex;
  flex-direction: column;
  max-width: 220px;
}

.assign-user__data-button {
  margin-top: auto;
}

.assign-user__data-label {
  --el-fonts--text-color: #{$label-color};
}
