@import "../../../assets/scss/shared/index";

.modal-condition {
  width: 300px;
  max-width: 100%;
}

.modal-condition__title {
  text-align: center;
  margin-bottom: 20px;
}

.modal-condition__panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  justify-content: center;
}
