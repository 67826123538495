@import "../../assets/scss/shared/index";

.header-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-control--admin {
  padding: 0 calc(50% - 1220px / 2);
  margin: 16px $source-offset;
}

.header-control--user {
  width: #{'min(100%, 1220px)'};
  margin: 0 auto;
  padding: 16px $source-offset;
}

.header-control__button {
  display: flex;
  align-items: center;

  --el-fonts--icon-color: #{$outline-color};
}

.header-control__panel {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.header-control__button-arrow {
  margin-right: 20px;
}
