@import '../../assets/scss/shared/index';

.t--source {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: $source-offset 0;
}

.t--source__head {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 $source-offset;

  > div,
  a {
    margin-bottom: calc(#{$source-offset} + 5px);
  }

  .search {
    margin-right: auto;
  }
}

.t--source__body {
  flex: auto;
  overflow: hidden;
}

.t--source__body-content {
  flex: auto;
  height: 100%;
  overflow: hidden;

  > svg {
    width: 100%;
  }
}

.t--source__body-view {
  padding: 0 $source-offset calc(#{$source-offset} * 2);
  flex: auto;
  height: 100%;

  //overflow: hidden;//TODO [Thunk].
  overflow: initial;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.t--source__body-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($t-source-grid-width, 1fr));
  grid-gap: $source-offset;
  padding: $source-offset;

  > svg {
    width: 100%;
  }
}

.t--source__body-cell {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: $source-offset;
}

.t--source__block {
  margin: $source-offset 0;
  padding: $source-offset;
  min-height: #{'min(300px, 50vh)'};
  background-color: $layout-color;
  border-radius: $block-radius;
  box-shadow: $box-shadow-big;
}
