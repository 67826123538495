@import "../../assets/scss/shared/index";

.rolling__name {
  display: flex;
  align-items: center;
}

.rolling__name-icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    --el-fonts--icon-color: #{$outline-color};
  }
}
