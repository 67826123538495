@import "../../assets/scss/shared/index";

.t--settings {
  display: flex;
  height: 100%;
  flex-direction: column;

  //animation: fade .3s ease;
  margin-top: calc(#{$source-offset} * 2);
  position: relative;
}

.t--settings__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 $source-offset;

  > h1, > a {
    margin-bottom: $source-offset;
  }
}

.t--settings__body {
  flex: auto;

  //overflow: hidden;
  position: relative;
}

.t--settings__body-content {
  display: grid;
  grid-gap: $source-offset;

  //margin-left: 28px;
  width: 100%;

  &:not(:last-child) {
    margin: 0 0 50px;
  }

  .toggle--primary {
    .text--lg {
      display: flex;
      align-items: center;
    }

    .icon--hint {
      margin-left: 8px;
    }
  }

  > h1 {
    display: flex;

    a {
      margin-left: auto;
    }
  }
}

.t--settings__body-overflow {
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: calc(#{$source-offset} * -1) 0 0 calc(#{$source-offset} * -1);
}

.t--settings__part {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;

  h1 {
    margin-bottom: 22px;
  }

  &:first-of-type {
    border-top: 1px solid $line-color;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $line-color;
  }
}

.t--settings__header-part {
  display: flex;
  justify-content: space-between;
  padding: 58px 0 28px;
  border-bottom: 1px solid $line-color;
}

.t--settings__fixed {
  grid-gap: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include xs(min) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  a {
    position: sticky;
    top: 0;
  }

  > div {
    position: sticky;
    bottom: 30px;
  }
}

.t--settings__row {
  > h1 {
    display: flex;

    a {
      margin-left: auto;
    }
  }
}

.t--settings__row--sm {
  width: #{'min(405px, 100%)'};
}

.t--settings__row--md {
  width: #{'min(620px, 100%)'};
}

.t--settings__row--mdx {
  width: #{'min(720px, 100%)'};
}

.t--settings__row--struct {
  width: #{'min(700px, 100%)'};
}

.t--settings__row--off {
  opacity: .5;
  pointer-events: none;
}

.t--settings__panel {
  display: flex;
  align-items: center;
  width: 100%;

  @include xs {
    flex-wrap: wrap;
  }
}

.t--settings__label {
  margin-right: auto;
  padding-right: $source-offset;

  --el-fonts--text-color: #{$label-color};
}

.t--settings__label, .t--settings__button {
  margin-bottom: 10px;
}

.t--settings__options {
  margin-top: 10px;
}

.t--settings__options--lg {
  width: 220px;
}
