@import "../../../../assets/scss/shared";

.create-event {
  width: 621px;
}

.create-event__head {
  margin: 4px 0 32px;
}

.create-event__row {
  margin-bottom: 20px;
}

.create-event__foot {
  margin-top: 30px;
  display: flex;
  max-width: 460px;
  margin-left: auto;
  justify-content: space-between;
}
