@import '../../assets/scss/shared/index';

.tabs {
  //display: flex;
  //flex-direction: column;
  height: 100%;

  .simplebar-scrollbar::before {
    --el-fonts--icon-color: transparent;
  }
}

.tabs--inner {
  padding: $source-offset;

  .tabs__links {
    --el-general--line-color: transparent;
  }
}

.tabs__links {
  height: 43px;

  .tabs--button & {
    height: 44px;
  }

  @include xs {
    height: 38px;
    margin: 0 -15px;
  }
}

.tabs__links-scroll {
  display: flex;
  min-width: 100%;
  width: fit-content;
  border-bottom: 1px solid $line-color;

  .tabs--button & {
    border: 0;
  }

  @include xs {
    border: 0;
    padding: 0 15px;
  }
}

.tabs__link {
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 42px;

  .tabs--button & {
    border: 1px solid $tabs-button-border-color;
    height: 44px;
    border-radius: $block-radius;
    justify-content: center;
    font-weight: 500;
    background-color: $layout-color;
    min-width: 140px;
    padding: 0 10px;

    &::before {
      content: none;
    }
  }

  @include xs(min) {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 2px;
    }
  }

  &:not(:last-child) {
    margin-right: $tabs-primary-link-offset;
  }

  &:not(.active) {
    --el-fonts--text-color: #{$label-color};
  }

  &:hover {
    --el-fonts--text-color: #{$outline-color};
  }

  &.active {
    .tabs--button & {
      background-color: $tabs-button-bg-color;
      color: $tabs-button-color;
    }

    &::before {
      background-color: $outline-color;
    }

    @include xs {
      --el-accent--monochrome-color: #{$outline-color};
      --el-fonts--text-color: #{$outline-color};
    }
  }

  span {
    color: $outline-color;
  }

  @include xs {
    --el-ui-text-lg: 18px;

    height: 38px;
    border: 1px solid $monochrome-color;
    border-radius: 8px;
    padding: 0 20px;

    --el-tabs-primary-link-offset: 10px;

    strong {
      font-weight: 500;
    }
  }
}

.tabs__content {
  flex: 1 1 auto;
  overflow: hidden;
}

.tabs__caption {
  margin-top: $source-offset;
  text-align: center;
}

.tabs--materials {
  height: auto;
  animation: fade 0.3s ease both;
  background-color: $layout-color;

  //border: 1px solid $line-color;
  box-shadow: 0 4px 4px rgb(0 0 0 / 20%);
  border-radius: $block-radius;
  min-height: 122px;
  width: 556px;
  max-width: 100%;
  padding: 15px 18px 20px 20px;
  z-index: 1;

  --el-source-offset: 9.5px;
  --el-tabs-primary-link-offset: 35px;

  @include xs {
    max-width: 100vw;
    margin-left: -41px;
  }
}

.tabs--button {
  --el-tabs-primary-link-offset: 20px;
}
