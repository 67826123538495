@import '../../../assets/scss/shared/index';

.notification-bell__menu {
  position: absolute;
  width: 460px;
  background-color: $bg-color;
  padding: 20px 40px 30px;
  border-radius: $block-radius;
  top: calc(100% + 10px);
  right: 0;
  box-shadow: $box-shadow-big;
  max-height: #{'min(60vh, 600px)'};
  display: flex;
  flex-direction: column;
}

.notification-bell__meta {
  overflow-y: scroll;
  flex: auto;
  margin-right: -40px;
  padding-right: 40px;
  display: grid;
  grid-gap: 10px;
}

.notification-bell__menu-title {
  margin-bottom: 20px;
}

.notification-small {
  padding: 15px 20px;
  background-color: $layout-second-color;
  border: $border-style;
  border-radius: $block-radius;
}

.notification-small__panel {
  margin-top: 14px;
}

.notification-small__reject {
  margin-right: 20px;
}

.notification-small__data {
  display: flex;
  grid-gap: 15px;
}

.notification-small__thumb {
  min-width: 80px;
  width: 80px;
  height: 45px;
  background-color: $layout-color;
  border-radius: $block-radius;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-small__thumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.notification-small__title {
  @include textTruncate(2);
}

.notification-small__desc {
  @include textTruncate(2);
}

.notification-small__date,
.notification-small__desc {
  margin-top: 10px;
}

.notification-small__date {
  --el-fonts--text-color: #{$label-color};
}

.notification-bell {
  position: relative;
}

.notification-bell__count {
  position: absolute;
  min-width: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 9.5px;
  background-color: #fe3347;
  top: -6px;
  padding: 0 5px;
  left: calc(100% - 10px);
  white-space: nowrap;

  --el-fonts--text-color: #{$white-color};
}
