@import '../../assets/scss/shared/index';

.table {
  position: relative;

  .toggle--success svg {
    margin-right: 14px;
  }
}

.table__thead,
.table__row {
  grid-gap: 20px;
}

.table__thead {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  border-radius: $block-radius;
  background-color: $layout-second-color;
  border: $border-style;
  box-shadow: $box-shadow-medium;
  padding: 8px 25px;
  margin-bottom: $source-offset;
  z-index: 1;
  min-height: 55px;
}

.table__thead-th {
  white-space: nowrap;

  --el-fonts--text-color: #{$icon-dark-color};
}

.table__switch {
  display: flex;
  align-items: center;
}

[class*='table__col'] {
  display: flex;
  align-items: center;
  min-width: 44px;
  flex: 0 0 auto;

  //outline: 1px solid red;
}

.table__col--name,
.table__col--firstName,
.table__col--email,
.table__col--roles,
.table__col--phoneNumber,
.table__col--createdAt,
.table__col--subdomain,
.table__col--address,
.table__col--lastLogin,
.table__col--plan,
.table__col--progress,
.table__col--completedCount,
.table__col--status,
.table__col--lastActiveAt,
.table__col--edit,
.table__col--confirm,
.table__col--author,
.table__col--title,
.table__col--description {
  //width: 33%;
  width: 200px;
  flex: auto;
}

.table__col--confirm {
  width: 270px;
  justify-content: flex-end;
}

.table__col--status {
  width: 140px;
}

.table__col--progress,
.table__col--completed {
  //width: 240px;
  min-width: 10px;
}

.table__col--progress {
  width: 105px;
}

.table__thead-sort {
  margin-left: 15px;

  &.square--active {
    --el-general--line-color: transparent;
    --el-fonts--icon-color: #{$outline-color};

    background-color: #f5f9fd;

    svg {
      transform: rotate(180deg);
    }
  }
}

.table__row {
  display: flex;
  padding: 12px 25px 11px;
  background-color: $table-row-color;
  border: $border-style;
  border-radius: $block-radius;
  margin-bottom: 10px;
}

.table__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: $outline-color;
  border-radius: $block-radius;
  padding: 8.5px 25px;
}

.table__panel-count {
  margin-right: auto;

  --el-fonts--text-color: #{$white-color};
}

.table__panel-count,
.table__panel-field {
  display: flex;
  align-items: center;
}

.table__panel-field {
  padding: 8.5px 12px;
  border-radius: $block-radius;
  cursor: pointer;

  --el-fonts--icon-color: #{$text-color};

  &:not(:last-child) {
    margin-right: 14px;
  }

  &:not(:hover) {
    --el-fonts--text-color: #{$white-color};
    --el-fonts--icon-color: #{$white-color};
  }

  &:hover {
    background-color: $button-hover-color;
  }
}

.table__panel-icon {
  margin-right: 10px;
}

[class*='table__row-td--'] {
  padding: 4px 0;
  width: 140px;
  justify-content: center;
  border-radius: $block-radius;
  color: $text-color;
  align-self: center;
}

.table__row-td--pending {
  background-color: $outline-second-color;

  --el-fonts--text-color: #{$outline-color};
}

.table__row-td--rejected {
  background-color: #ffdae1;

  --el-fonts--text-color: #eb5757;
}

.table__row-td--accepted {
  background-color: #fff4d8;

  --el-fonts--text-color: #ffad13;
}

.table__col--lock {
  width: 50%;
  flex: auto;
  display: flex;
  align-self: center;

  --el-fonts--text-color: #{$label-color};

  svg {
    margin-right: 10px;
    width: 13px;
    height: 15px;
    margin-bottom: 2px;
  }
}

.table__row--lock {
  > div:not(.table__col--lock) {
    opacity: 0.5;
  }
}

.table__row-td {
  a {
    color: $text-color;

    &:hover {
      --el-fonts--text-color: #{$outline-color};
    }

    &:visited:not(:hover) {
      color: $label-color;
    }
  }
}

.table__empty {
  --el-fonts--text-color: #{$label-color};
}

.table__end-panel {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.table__end-more {
  margin-right: auto;
}

.table__start-panel {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
  margin-bottom: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.table__register-menu {
  position: absolute;
  right: -44px;
  top: 10px;
  user-select: none;

  &:not(.table__register-menu--without) {
    width: 400px;
    height: 310px;
  }
}

.table__register-menu--without {
  width: 200px;
}

.table__scroll {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 100%;
}

.table__caption-name {
  margin-right: auto;
}

.table__export {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  min-width: 200px;
}

.table__row--single {
  --el-general--element-color: #{$white-color};
}
