@import "../../assets/scss/shared/index";

.preloader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $layout-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;

  svg {
    width: 100%;
    height: 250px;
    font-size: 170px;
  }

  .text {
    fill: none;
    stroke-width: 5;
    stroke-linejoin: round;
    stroke-dasharray: 70 330;
    stroke-dashoffset: 0;
    animation: stroke 6s infinite linear;
  }

  .text:nth-child(5n + 1) {
    stroke: $brand-color;
    animation-delay: -1.2s;
  }

  .text:nth-child(5n + 2) {
    stroke: #b6b6b6;
    animation-delay: -2.4s;
  }

  .text:nth-child(5n + 3) {
    stroke: $brand-color;
    animation-delay: -3.6s;
  }

  .text:nth-child(5n + 4) {
    stroke: #b6b6b6;
    animation-delay: -4.8s;
  }

  .text:nth-child(5n + 5) {
    stroke: $brand-color;
    animation-delay: -6s;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: -400;
    }
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: -400;
    }
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -400; } }

@keyframes stroke {
  100% {
    stroke-dashoffset: -400; } }
