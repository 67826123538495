@import '../../../assets/scss/shared/index';

.profile-control {
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile-control__name {
  cursor: pointer;
  text-transform: capitalize;
  padding-right: 20px;
  margin-left: 30px;
  word-spacing: 3px;

  --el-fonts--text-color: #{$title-color};
}

.profile-control__mode {
  @include font($label-color 14px 19px);

  text-align: right;
  margin: 0 21px -10px 0;
}

.profile-control__data {
  display: flex;
  align-items: center;
  cursor: pointer;
  word-spacing: -3px;
}

.profile-control__menu {
  position: absolute;
  top: calc(100% + 25px);
  right: calc(100% - 3px);
  width: 240px;
  padding: 11px 0 16px;
  background-color: $layout-color;
  box-shadow: $box-shadow-big-short;
  border-radius: $block-radius;
  transition: $move-ease;
  animation: fade 0.3s ease;
  z-index: 1;
}

.profile-control__menu-item {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  --el-fonts--text-color: #{$text-dark-color};
  --el-fonts--icon-color: #{$brand-color};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    background-color: $brand-color;
    opacity: 0;
  }

  &.active {
    &::before {
      opacity: 1;
    }

    --el-fonts--text-color: #{$white-color};
    --el-fonts--icon-color: #{$white-color};
  }

  &:hover:not(.active) {
    &::before {
      opacity: 0.5;
    }
  }
}

.profile-control__arrow {
  transition: $move-ease;
  position: absolute;
  top: 6px;
  right: 0;

  &:last-child {
    --el-fonts--icon-color: #{$text-color};
  }
}

.profile-control__menu-icon {
  width: 24px;
  margin-right: 11px;
}
