@import "../../assets/scss/shared/index";

.logo {
  overflow: $logo-overflow;
}

.logo__type {
  transition: $move-ease;
}

.logo--active {
  --el-logo-animation-text: fadein .3s ease both;

  .logo__type {
    transform: translateX(-27px);
  }
}

.logo__text {
  animation: $logo-animation-text;
}

.logo__bee {
  animation: fade .3s ease .3s both;
}
