@import "../../../assets/scss/shared/index";

.modal-create {
  width: 621px;
  max-width: 100%;
}

.modal-create__head {
  margin: 4px 0 32px;
}

.modal-create__row {
  margin-bottom: 20px;
}

.modal-create__foot {
  margin-top: 30px;
  display: flex;
  max-width: 460px;
  margin-left: auto;
  justify-content: center;
  grid-gap: 20px;
  flex-wrap: wrap;
}
