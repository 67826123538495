@import "../../../../assets/scss/shared/index";

.cancel {
  width: 300px;
  text-align: center;

  .text--lg {
    margin-bottom: 30px;
  }

  .button--b {
    margin-right: $source-offset;
  }
}
