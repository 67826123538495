@import '../../assets/scss/shared/index';

.attach-triple {
  display: flex;
  flex-flow: row-reverse wrap;
  position: relative;
  text-align: left;
  align-items: center;

  @include less {
    flex-direction: column-reverse;
    grid-gap: 10px;
  }
}

.attach-triple__label {
  width: #{'min(160px, 100%)'};
  margin-right: auto;
}

.attach-triple__data {
  background-color: $events-color;
  padding: 20px;
  border-radius: $block-radius;
  flex: auto;
  display: grid;
  grid-gap: 18px;

  @include less {
    width: 100%;
  }
}

.attach-triple__btn-icon {
  margin-right: 10px;
}

.attach-triple__data-row {
  display: grid;
  grid-gap: 12px;
  align-items: center;

  @include less(min) {
    grid-template-columns: 87px auto;
  }

  &:not(.attach-triple__data-row--active) {
    opacity: 0.5;
  }
}

.attach-triple__attach-file {
  background: $input-bg-color;
  box-shadow: $box-shadow-medium;
  border-radius: $block-radius;
  padding: 10px 15px;
  position: relative;
}

.attach-triple__attach-trash {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 30px);
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-color: $events-color;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  &:hover {
    --el-fonts--icon-color: #{$brand-color};
  }
}

.attach-triple__attach-label {
  line-height: 18px;
}

.attach-triple__attach-cover {
  margin-top: 10px;

  --el-accent--success-color: #{$brand-color};
}

.attach-triple__cover {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  border-radius: $block-radius;
  overflow: hidden;
}

.attach-triple__cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
