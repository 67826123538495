@import '../../../assets/scss/shared/index';

.card-mobile {
  position: relative;
  z-index: 0;
  border: 1px solid transparent;
  border-radius: 6px;
  margin-bottom: 20px;

  @include less {
    margin-bottom: 15px;
  }
}

.card-mobile__wrapper {
  cursor: pointer;
  border-radius: 6px;
  background-color: $layout-color;
  box-shadow: $box-shadow-medium;
  padding: 20px;

  @include less {
    padding: 15px;
  }
}

.card-mobile__head {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.card-mobile__thumb {
  min-width: 80px;
  width: 80px;
  height: 53px;
  position: relative;
  border-radius: $block-radius;
  overflow: hidden;

  @include less {
    min-width: 50px;
    width: 50px;
    height: 40px;
  }
}

.card-mobile__title {
  --el-ui-text-lg: 14px;
  --el-ui-text-line-lg: 21px;

  @include textTruncate(2);
}

.card-mobile__dots,
.card-mobile__arrow {
  margin-left: auto;

  + .card-mobile__arrow {
    margin: 0;
  }
}

.card-mobile__thumb-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card-mobile__options {
  position: absolute;
  top: 100%;
  right: 100%;
  width: 170px;
  animation: slideUp 0.3s ease, fade 0.3s ease;
}

.card-mobile__dots {
  position: relative;
}

.card-mobile__desc,
.card-mobile__label {
  --el-fonts--text-color: #{$label-color};
}

.card-mobile__desc {
  @include textTruncate(3);

  margin-top: 10px;
}

.card-mobile__hr {
  margin: 20px 0 10px;
  border-bottom: 1px solid $border-force-color;
}

.card-mobile__body {
  display: grid;
  grid-gap: 10px;
}

.card-mobile__body-row {
  display: flex;
  grid-gap: 15px;
}

.card-mobile__body-col {
  width: 50%;
}

.card-mobile__link,
.card-mobile__box {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.card-mobile__link {
  --el-fonts--text-color: #{$outline-color};
  --el-fonts--icon-color: #{$outline-color};
}
