@import '../../assets/scss/shared/index';

.content-menu {
  background-color: $layout-color;
  border-radius: $block-radius;
  box-shadow: $box-shadow-medium;
  overflow: hidden;
}

.content-menu__item {
  height: 41px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;

  &:hover {
    background-color: $outside-color;

    --el-fonts--text-color: #{$outline-color};
  }
}
