// Variables:
//
// Should type for: | `$element-state-property`
//                  | `$state-property`
//                  | `$property`

//Default colors:

$primary-color: var(--el-default--primary-color);
$success-color: var(--el-default--success-color);
$danger-color: var(--el-default--danger-color);
$warning-color: var(--el-default--warning-color);
$test-badge-color: var(--el-default--test-badge-color);
$homework-badge-color: var(--el-default--homework-badge-color);
$materials-badge-color: var(--el-default--materials-badge-color);
$page-badge-color: var(--el-default--page-badge-color);
$colorBlue: var(--el-default--colorBlue);
$colorEdbee: var(--el-default--colorEdbee);
$colorPink: var(--el-default--colorPink);
$colorPurple: var(--el-default--colorPurple);
$colorOrange: var(--el-default--colorOrange);
$colorGreen: var(--el-default--colorGreen);
$dark: var(--el-default--dark);
$grey: var(--el-default--grey);

//Custom properties colors:

$icon-bg-color: var(--el-general--icon-bg-color);
$layout-color: var(--el-general--layout-color);
$disabled-color: var(--el-general--disabled-color);
$layout-second-color: var(--el-general--layout-second-color);
$layout-third-color: var(--el-general--layout-third-color);
$bg-color: var(--el-general--bg-color);
$line-color: var(--el-general--line-color);
$outline-color: var(--el-layout--outline-color);
$outline-second-color: var(--el-layout--outline-second-color);
$dashed-color: var(--el-layout--dashed-color);
$build-color: var(--el-layout--build-color);
$border-color: var(--el-layout--border-color);
$border-second-color: var(--el-layout--border-second-color);
$border-third-color: var(--el-layout--border-third-color);
$border-monochrom-color: var(--el-layout--border-monochrom-color);
$border-light-color: var(--el-layout--border-light-color);
$border-force-color: var(--el-layout--border-force-color);
$border-dark-color: var(--el-layout--border-dark-color);
$border-easy-color: var(--el-layout--border-easy-color);
$event-border-color: var(--el-layout--event-border-color);
$outside-color: var(--el-layout--outside-color);
$monochrome-color: var(--el-accent--monochrome-color);
$name-color: var(--el-accent--name-color);
$events-color: var(--el-layout--events-color);
$error-color: var(--el-general--error-color);
$part-color: var(--el-layout--part-color);
$event-section-bg-color: var(--el-layout--events-section-bg-color);
$content-card-bg-color: var(--el-layout--content-card-bg-color);
$bg-dark-color: var(--el-general--bg-dark-color);
$element-color: var(--el-general--element-color);
$input-color: var(--el-ui--input-color);
$ui-block-color: var(--el-ui--block-color);
$correct-color: var(--el-accent--correct-color);
$no-correct-color: var(--el-accent--no-correct-color);
$accept-color: var(--el-accent--accept-color);
$accept-color-2: var(--el-accent--accept-color-2);
$mark-color: var(--el-general--mark-color);
$element-file-color: var(--el-ui--element-file-color);
$element-homework-color: var(--el-ui--element-homework-color);
$element-test-color: var(--el-ui--element-test-color);
$element-page-color: var(--el-ui--element-page-color);
$element-image-color: var(--el-ui--element-image-color);
$element-stream-color: var(--el-ui--element-stream-color);
$element-video-color: var(--el-ui--element-video-color);
$element-webinar-color: var(--el-ui--element-webinar-color);

//
$brand-color: var(--el-general--brand-color);
$accent-color: var(--el-general--accent-color);
$action-color: var(--el-general--action-color);
$white-color: var(--el-general--white-color);
$black-color: var(--el-general--black-color);

//font colors:

$text-color: var(--el-fonts--text-color);
$text-dark-color: var(--el-fonts--text-dark-color);
$label-color: var(--el-fonts--label-color);
$link-color: var(--el-fonts--link-color);
$title-color: var(--el-fonts--title-color);
$icon-color: var(--el-fonts--icon-color);
$icon-fill-color: var(--el-fonts--icon-fill-color);
$icon-dark-color: var(--el-fonts--icon-dark-color);
$sheet-color: var(--el-fonts--sheet-color);
$success-color: var(--el-accent--success-color);

//ui colors:
$hover-icon-bg-color: var(--el-ui--hover-icon-bg-color);
$builder-head-bg-color: var(--el-ui--builder-head-bg-color);
$checkbox-icon-second-color: var(--el-ui--checkbox-icon-second-color);
$checkbox-icon-color: var(--el-ui--checkbox-icon-color);
$contour-in-progress-border-color: var(--el-ui--contour-in-progress-border-color);
$contour-in-progress-bg-color: var(--el-ui--contour-in-progress-bg-color);
$contour-accepted-color: var(--el-ui--contour-accepted-color);
$contour-rejected-bg-color: var(--el-ui--contour-rejected-bg-color);
$contour-accepted-bg-color: var(--el-ui--contour-accepted-bg-color);
$contour-pending-bg-color: var(--el-ui--contour-pending-bg-color);
$table-row-color: var(--el-ui--table-row-color);
$card-border-color: var(--el-ui--card-border-color);
$card-block-color: var(--el-ui--card-block-color);
$border-square-color: var(--el-ui--border-square-color);
$square-border-color: var(--el-ui--square-border-color);
$aside-icon-color: var(--el-ui--aside-icon-color);
$warn-icon-color: var(--el-ui--warn-icon-color);
$input-bg-color: var(--el-ui--input-bg-color);
$button-color: var(--el-ui--button-color);
$button-text-color: var(--el-ui--button-text-color);
$button-hover-color: var(--el-ui--button-hover-color);
$button-border-active-color: var(--el-ui--button-border-active-color);
$button-active-color: var(--el-ui--button-active-color);
$progress-bg-color: var(--el-ui--progress-bg-color);
$button-error-bg--color: var(--el-ui--button-error-bg-color);
$button-error-border--color: var(--el-ui--button-error-border-color);
$button-success-bg--color: var(--el-ui--button-success-bg-color);
$button-success-border--color: var(--el-ui--button-success-border-color);
$pagination-progress-color: var(--el-ui--pagination-progress-color);
$target-color: var(--el-ui--target-color);
$t-panel-head-pg: var(--el-t-panel-head-pg);
$app-body-aside-width: var(--el-app-body-aside-width);
$ui-field-mg: var(--el-ui-field-mg);
$learn-number-color: var(--el-ui--learn-number-color);
$learn-number-shadow-color: var(--el-ui--learn-number-shadow-color);
$t-source-grid-width: var(--el-t-source-grid-width);
$t-panel-head-height: var(--el-t-panel-head-height);
$t-aside-head-mg: var(--el-t-aside-head-mg);
$logo-type: var(--el-logo-type);
$logo-animation-text: var(--el-logo-animation-text);
$logo-overflow: var(--el-logo-overflow);
$tabs-primary-link-offset: var(--el-tabs-primary-link-offset);
$tabs-button-bg-color: var(--el-ui--tabs-button-bg-color);
$tabs-button-color: var(--el-ui--tabs-button-color);
$tabs-button-border-color: var(--el-ui--tabs-button-border-color);
$source-offset: var(--el-source-offset);
$block-color: var(--el-layout--block-color);
$aside-color: var(--el-layout--aside-color);

//Media:
$box-shadow-span: var(--el-box-shadow-span);
$box-shadow-big: var(--el-box-shadow-big);
$box-shadow-big-short: var(--el-box-shadow-big-short);
$box-shadow-big-short-dark: var(--el-box-shadow-big-short-dark);
$box-shadow-medium: var(--el-box-shadow-medium);
$box-shadow-medium-dark: var(--el-box-shadow-medium-dark);
$box-shadow-medium-short: var(--el-box-shadow-medium-short);
$box-shadow-medium-short-dark: var(--el-box-shadow-medium-short-dark);
$box-shadow-medium-inside: var(--el-box-shadow-medium-inside);
$box-shadow-small: var(--el-box-shadow-small);
$box-shadow-small-dark: var(--el-box-shadow-small-dark);
$box-shadow-smallest: var(--el-box-shadow-smallest);
$aside-item-bg: var(--el-ui--aside-item-bg);
$border-style: var(--el-border-style);
$border-second-style: var(--el-border-second-style);
$border-third-style: var(--el-border-third-style);
$nav-font-weight: var(--el-nav-font-weight);
$logo-width: var(--el-logo-width);
$nav-margin-right: var(--el-nav-margin-right);
$ui-text-lg: var(--el-ui-text-lg);
$ui-text-line-lg: var(--el-ui-text-line-lg);
$ui-text-md: var(--el-ui-text-md);
$ui-text-line-md: var(--el-ui-text-line-md);
$ui-text-sm: var(--el-ui-text-sm);
$ui-text-line-sm: var(--el-ui-text-line-sm);
$ui-text-xs: var(--el-ui-text-xs);
$ui-text-line-xs: var(--el-ui-text-line-xs);
$move-ease: all 0.3s ease;
$default-offset: 15px;
$block-radius: 6px;
$element-radius: 4px;
$shadow: 0 1px 5px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
$family: 'Montserrat', sans-serif;
