@import '../../assets/scss/shared/index';

.nav {
  margin-right: $nav-margin-right;

  span {
    cursor: pointer;
  }

  svg {
    min-width: 35px;
    width: 35px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 4px;
  }

  &.nav--support {
    margin-top: auto;
  }

  &.nav--mobile {
    margin: 0;
  }
}

.nav__link {
  max-width: 250px;
  margin-left: auto;
  display: block;
  position: relative;
  z-index: 1;

  //transition: $move-ease;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include font($text-color 16px 57px 500);

  padding: 0 7px;
  word-break: break-word;

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    color: $input-color;
    font-weight: 500;
    width: 180px;
    white-space: initial;

    //word-spacing: 20px;
    .nav__item.active & {
      font-weight: $nav-font-weight;
    }
  }

  .nav__item.active & {
    font-weight: $nav-font-weight;
  }

  //.nav--user & {
  //  padding: 0 15px;
  //}
  .nav--support & {
    --el-fonts--icon-color: #{$label-color};
    --el-fonts--text-color: #{$label-color};

    font-weight: 500;
  }

  .nav--mobile & {
    margin: 0;
    padding: 0;
  }
}

.nav__item {
  padding-left: 15px;
  display: block;
  overflow: hidden;
  border-radius: $block-radius;

  &.active {
    background-color: $aside-item-bg;
  }

  --el-fonts--icon-color: #{$aside-icon-color};

  .nav--user &,
  .nav--mobile & {
    --el-general--bg-color: transparent;

    &.active {
      --el-fonts--icon-color: #{$brand-color};
    }
  }

  .nav--support & {
    position: relative;

    --el-general--bg-color: transparent;

    &.active {
      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        // background-color: $action-color;
        width: 100%;
        max-width: 270px;
        border-radius: 6px;
      }
    }
  }

  .nav--mobile & {
    padding: 0;
    margin: 0;
  }
}
