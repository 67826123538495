@import "../../../../assets/scss/shared/index";

.agency-roles {
  width: #{'min(700px, calc(100vw - 30px))'};
  position: relative;
  display: flex;
  flex-direction: column;
}

.agency-roles__title {
  margin-bottom: 33px;
}

.agency-roles__data, .agency-roles__data-row {
  display: grid;
  grid-template-columns: calc(100% - 230px) 220px;
  grid-gap: 10px;
  align-items: flex-start;
}

.agency-roles__data-label {
  --el-fonts--text-color: #{$icon-dark-color}
}

.agency-roles__data-row {
  background-color: $element-color;
  border-radius: $block-radius;
  padding: 10px 15px;
}

.agency-roles__data-row, .agency-roles__btn {
  grid-column: span 2;
}

.agency-roles__btn {
  margin: 10px 0 0 auto;
}

.agency-roles__data-name {
  display: flex;
  align-items: center;
}

.agency-roles__user {
  display: grid;
  grid-gap: 10px;
}

.agency-roles__role--scroll {
  background-color: $element-color;
  padding: 10px;
  border-radius: $block-radius;
  margin-top: 10px;
}

.agency-role {
  padding: 5px 10px;
  cursor: pointer;
}

.agency-role--active {
  --el-fonts--text-color: #{$brand-color};
}
