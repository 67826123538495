@import "../../../../assets/scss/shared/index";

.homework {
  width: #{'min(1075px, calc(100vw - 30px))'};
  position: relative;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 33px;
  }
}

.homework__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.homework__data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .button--primary {
    margin-top: 6px;
  }
}

.homework__data-item {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $source-offset;
  }
}

.homework__score {
  display: grid;
  grid-template-columns: repeat(auto-fit, 160px calc(100% - 160px));
}

.homework__score-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  grid-gap: 20px;
}

.homework__score__item {
  display: grid;
  grid-template-columns: 140px 70px;
  grid-gap: 20px;

  input {
    background-color: $input-bg-color;
    border-radius: $block-radius;
    height: 44px;
    padding: 0 15px;
  }
}
