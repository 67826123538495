@import '../../assets/scss/shared/index';

.card {
  position: relative;
  margin-top: 48px;

  @include sm {
    margin-top: 40px;
  }

  @include xs {
    margin-top: 38px;
  }
}

.card__thead {
  display: flex;
  align-items: center;
  border-radius: $block-radius;
  background-color: $layout-second-color;
  border: $border-style;
  box-shadow: $box-shadow-medium;
  margin-bottom: $source-offset;
  z-index: 1;
}

.card__block-area {
  padding: 5px 10px;
}

.card__thead--sticky {
  position: sticky;
  top: 0;
}

.card__thead:not(.card__thead--sm) {
  padding: 13.5px 29px;
}

.card__thead--sm {
  padding: 8px 29px;
}

.card__block {
  padding: 30px 29px;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border: $border-second-style;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: $layout-color;
  box-shadow: $box-shadow-medium;
  margin-bottom: 20px;

  &:hover {
    border-color: $card-border-color;
  }
}

.card__block:not(.card__block--wrap) {
  height: 130px;
}

.card__block--wrap {
  flex-wrap: wrap;
}

.card__attach-icon {
  margin-left: 15px;
}

.card__attach-icon--active {
  transform: rotate(180deg);
  transform-origin: 50% 9px;

  --el-general--disabled-color: #{$text-color};
}

.card__attach-content {
  border: $border-style;
  padding: 15px;
  background-color: $events-color;
  margin: 15px 0 10px;
  border-radius: $block-radius;

  @include xs {
    margin-bottom: 0;
  }
}

.card__attach-unit {
  display: flex;
  align-items: center;

  --el-general--disabled-color: #{$text-color};

  &:hover {
    --el-fonts--text-color: #{$outline-color};
  }
}

.card__attach-title {
  display: flex;
  align-items: center;
}

.card__attach-title:not(.card__attach-title--active) {
  --el-fonts--text-color: #{$label-color};
}

.card__attach {
  @include xs(min) {
    width: calc(100% + 60px);
    border-top: $border-style;
    padding: 15px 20px;
    margin: 30px -30px -30px;
  }

  @include xs {
    border-top: 1px solid $border-force-color;
    margin: 10px 0 0;
    padding: 10px 0 0;
  }
}

.card__block-content {
  width: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.card__block-thumb {
  height: 68px;
  border-radius: $block-radius;
  background-color: $events-color;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.card__image {
  width: 100%;
  height: 100%;
  box-shadow: $box-shadow-medium;
  border-radius: $block-radius;
  object-fit: cover;
  object-position: center;
}

.card__block-name,
.card__block-text,
.card__user-name,
.card__user-text {
  width: 100%;
}

.card__user-text {
  @include textTruncate(2);

  + .card__user-agency {
    margin-top: 11px;
  }
}

.card__user-agencies {
  display: flex;
  align-items: center;
  margin-top: auto;
  grid-gap: 10px;
  flex: auto;

  @include less {
    display: grid;
    grid-gap: 20px;
  }
}

.card__user-entry {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;

  @include xs(min) {
    padding: 15px 17px;
    border: 1px solid $border-easy-color;
    border-radius: 10px;
  }
}

.card__user-created {
  --el-fonts--text-color: #{$label-color};
}

.card__user-buttons {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;

  .button--outlined {
    --el-ui--button-active-color: #{$error-color};
    --el-ui--button-hover-color: #{$error-color};
    --el-general--brand-color: #{$error-color};

    &:hover {
      --el-general--brand-color: #{$white-color};
    }
  }

  @include less(min) {
    margin-left: auto;
    justify-content: flex-end;
  }
}

.card__user-button {
  @include less {
    flex: auto;
  }
}

.card__block-name,
.card__user-name {
  @include xs(min) {
    @include textTruncate(1);
  }

  @include xs {
    @include textTruncate(2);
  }

  --el-fonts--text-color: #{$title-color};

  + div {
    margin-top: 8px;
  }
}

.card__block-text {
  --el-fonts--text-color: #{$label-color};

  @include textTruncate(2);
}

.card__thead-th {
  white-space: nowrap;
}

.card__thead-th:not(.card__thead-th--active) {
  --el-fonts--text-color: #{$icon-dark-color};
}

[class*='card__col'] {
  display: flex;
  align-items: center;
}

.card__block-td {
  flex-wrap: wrap;
  pointer-events: none;
}

.card__col--name,
.card__col--email,
.card__col--roles,
.card__col--miniCoverFileId {
  //width: 33%;
  //flex: auto;
}

.card__col--name {
  width: 35%; //25%;
  margin: 0 20px;
}

.card__col--startDate,
.card__col--author,
.card__col--participantCount,
.card__col--updatedAt,
.card__col--questions,
.card__col--maxScore,
.card__col--agency {
  margin: 0 10px;
  width: 20%;
}

.card__col--author {
  @include textTruncate(2);
}

.card__col--questions {
  pointer-events: all;
}

.card__col--testInfo {
  width: 30%;
  margin: 0 10px;
}

.card__block-box {
  display: flex;
  align-items: center;

  --el-general--icon-bg-color: #{$events-color};
}

.card__block-eye {
  box-shadow: none;
  pointer-events: all;
  margin-right: 15px;
  border-radius: 4px;

  &:hover {
    --el-general--disabled-color: #{$outline-color};
  }
}

.card__col--miniCoverFileId {
  width: 120px;
  min-width: 120px;
}

.card__thead-sort {
  margin-left: 15px;

  &.square--active {
    --el-ui--square-border-color: transparent;
    --el-general--disabled-color: #{$outline-color};

    background-color: $contour-in-progress-bg-color;

    svg {
      transform: rotate(180deg);
    }
  }
}

.card__block-dots {
  position: absolute;
  right: 5px;
  top: 0;
  padding: 20px 15px;

  &:hover {
    --el-general--disabled-color: #{$text-color};
  }
}

.card__block-options {
  position: absolute;
  top: 100%;
  right: 100%;
  width: 170px;
  animation: slideUp 0.3s ease, fade 0.3s ease;
}

.card__start-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;

  @include xs {
    flex-wrap: wrap;
  }
}

.card__start-panel__link {
  --el-fonts--text-color: #{$outline-color};
}

.card__start-panel__btn {
  @include xs {
    position: fixed;
    bottom: 5vh;
    right: 15px;
    z-index: 1;
    font-size: 0;
    min-width: 80px !important;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 0 !important;
  }

  svg {
    @include xs(min) {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}

.card__start-panel:not(.card__start-panel--sm) {
  margin: 48px 0 20px;

  @include sm {
    margin: 40px 0 20px;
  }

  @include xs {
    margin: 38px 0;
  }
}

.card__start-panel--sm {
  margin: 20px 0 40px;
}

.card__empty {
  --el-fonts--text-color: #{$label-color};
}

.card__end-panel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;

  @include xs {
    display: block;
  }
}

.card--scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 0;
}

.card__scrolled {
  flex: auto;
  overflow: hidden;
  margin: -20px -30px 20px;
}

.card__scrolled-content {
  padding: 20px 30px 0;
}

.card__attach-unit__date {
  margin-left: 15px;
}

.card__limited-btn {
  position: relative;
}

.card__limited-text {
  position: absolute;
  top: 50%;
  right: calc(100% + 30px);
  text-align: right;
  transform: translate(0, -50%);
  width: 120%;

  --el-fonts--text-color: #{$label-color};
}

.card__block-link {
  pointer-events: all;
  display: flex;
  align-items: center;
  color: $outline-color;

  --el-fonts--text-color: #{$outline-color};
  --el-general--disabled-color: #{$outline-color};

  &:visited:not(:hover) {
    color: $label-color;
  }
}

.card__block-sideEffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.card__user-data {
  display: flex;
  grid-gap: 20px;
  flex: auto;

  @include xs {
    flex-wrap: wrap;
  }

  //  @include sm(min) {
  //    padding-right: calc(30% + 30px);
  //  }
}

.card__user {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  background-color: $layout-color;
  box-shadow: $box-shadow-big-short;
  border-radius: 10px;
  padding: 29px;
  margin-bottom: $source-offset;
  border: $border-second-style;

  &:hover {
    border-color: $card-border-color;
  }

  @include xs(min) {
    //grid-template-columns: #{'repeat(auto-fit, 240px calc(100% - 260px))'};
    //grid-template-columns: #{'repeat(auto-fit, minmax(240px, 1fr) calc(100% - 260px))'};
  }

  @include xs {
    padding: 20px;
  }
}

.card__user--point {
  cursor: pointer;
}

.card__user-thumb {
  min-width: 260px;
  height: 146px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $events-color;
  border-radius: 10px;

  + .card__user-entry {
    .card__user-agencies {
      @include minmax($CASE_XS, $CASE_SM) {
        width: calc(100% + 260px);
        margin-left: -260px;
        margin-top: 30px;
      }
    }
  }

  @include xs {
    width: 100%;
    height: #{'min(272px, 40vh)'};
  }

  @include less {
    height: #{'min(220px, 20vh)'};
  }
}

.card__user-thumb__icon {
  min-width: 70%;
  min-height: 70%;
}

.card__user-date {
  position: absolute;
  top: 5px;
  left: 5px;
  box-shadow: $box-shadow-medium-inside;
  min-width: 130px;
  height: 28px;
  padding: 0 13px;
  justify-content: center;
  z-index: 0;
  display: flex;
  align-items: center;

  --el-general--disabled-color: #{$text-color};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $layout-color;
    z-index: -1;
    border-radius: $element-radius;
    opacity: 0.8;
  }

  svg {
    width: auto;
    height: auto;
    margin: 0 5px;
  }
}

.card__user-agency {
  margin: 0 0 0 2px;
  display: flex;
  align-items: center;

  --el-general--disabled-color: #{$outline-color};
}

.card__user-agency__name {
  margin-left: 16px;

  --el-fonts--text-color: #{$label-color};
}

.card__user-payment {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  grid-gap: 20px;
  align-items: flex-end;

  @include xs {
    margin: 0 -20px -20px;
    background-color: $event-section-bg-color;
    width: calc(100% + 40px);
    padding: 20px;
    flex-wrap: wrap;
  }
}

.card__payment-label {
  display: flex;
  grid-gap: 20px;
  align-items: center;
}

.card__payment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  flex-wrap: wrap;

  @include xs(min) {
    background-color: $event-section-bg-color;
    border: 1px solid $event-border-color;
    padding: 10px 16px;
    border-radius: 10px;
  }

  @include lg(min) {
    width: calc(100% - 520px);
  }

  @include lg {
    flex: auto;
  }
}

.card__payment-link {
  @include less {
    flex: auto;
  }
}

.card__payment-button {
  @include less {
    width: 100%;
  }
}
