@import "../../../../../assets/scss/shared/index";

.settings-tests {
  width: 610px;
  max-width: 100%;
}

.settings-tests__label {
  margin: 30px 0;

  --el-fonts--text-color: #{$label-color};
}

.settings-tests__panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include xs {
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
  }
}

.settings-tests__btn {
  @include xs(min) {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.settings-tests__copy {
  margin: 30px -50px;

  @include xs {
    margin: 30px -20px;
  }
}

.settings-tests__target {
  padding: 12px 50px;
  background-color: $events-color;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 2;

  @include xs {
    padding: 12px 15px;
  }
}

.settings-tests__row {
  padding: 0 50px;
  margin: 20px 0;

  @include xs {
    padding: 0 20px;
  }
}

.settings-tests__field {
  margin-bottom: 20px;
}
