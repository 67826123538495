@import "../../assets/scss/shared/index";

.copy-clipboard {
  display: flex;
  flex-flow: row-reverse wrap;
  position: relative;
  text-align: left;
  align-items: center;

  @include less {
    flex-direction: column-reverse;
    grid-gap: 10px;
  }
}

.copy-clipboard__label {
  @include font($text-color 16px 24px 500);

  width: #{'min(160px, 100%)'};
  margin-right: auto;
  padding-right: $default-offset;
}

.copy-clipboard__text {
  flex: 1 1 #{'min(290px, calc(100% - 160px))'};
  background-color: $layout-color;
  border-radius: $block-radius;
  padding: 11px 15px;
}

.copy-clipboard__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  left: calc(100% + 15px);

  &:hover {
    --el-fonts--icon-color: #{$outline-color};
  }
}
