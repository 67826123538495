@import '../../../../assets/scss/shared';

[class*='tags--'] {
  //color: $text-color;
  //font-weight: 500;
  label {
    @include font($text-color 16px 21px 500);

    width: #{'min(160px, 100%)'};
    padding-right: 15px;
  }
}

.tags--primary {
  position: absolute;
  top: 100%;
  width: 100%;
}

.tags__polygon {
  z-index: 3;
  background-color: $layout-color;
  padding: 14px 13px 21px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: $box-shadow-big-short;
  border: 1px solid $outside-color;
  border-radius: $block-radius;

  .tags--error & {
    --el-layout--outside-color: #{$error-color};
  }

  i {
    cursor: pointer;
    margin-left: 15px;
  }

  div {
    &:not(:last-child) {
      margin: 0 10px 10px 0;
    }
  }

  > .text--md {
    background-color: $outside-color;
    padding: 1px 8px;
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
  }
}

.tags--field {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: center;

  .tags__polygon {
    flex: auto;
    max-width: calc(100% - 160px);
  }
}

.tags--primary__clear,
.tags--field__clear {
  position: absolute;
  bottom: 8px;
  right: 14px;

  --el-fonts--text-color: #{$label-color};

  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
    margin-left: 16px;
  }
}
