@import '../../assets/scss/shared/index';

.storage__head {
  display: flex;
  margin-bottom: 20px;

  @include less {
    margin-bottom: 10px;
  }
}

.storage__body {
  display: grid;
  grid-template-columns: repeat(auto-fit, 237px calc(100% - 247px));
  grid-gap: 20px;

  @include xs {
    grid-template-columns: repeat(auto-fit, 190px calc(100% - 200px));
    grid-gap: 10px;
  }

  @include less {
    grid-template-columns: 1fr;
  }
}

.storage__middle {
  display: grid;

  @include less(min) {
    grid-template-columns: repeat(auto-fit, 237px calc(100% - 247px));
    grid-gap: 20px;
  }

  .input--eject {
    input {
      padding: 0 15px 0 53px;
    }

    svg {
      position: absolute;
      top: 12px;
      left: 15px;
    }
  }

  > div {
    margin-bottom: 20px;

    @include less {
      margin-bottom: 10px;
    }
  }
}

.storage__controls {
  display: grid;
  grid-template-columns: repeat(auto-fit, 44px);
  grid-gap: 20px;

  @include less {
    grid-gap: 10px;
  }
}

.storage__search {
  flex: auto;
}

.storage__search-button {
  display: none;
}

.storage__body-view {
  flex: auto;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-medium;
  background-color: $layout-color;

  > div {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.storage__polygon {
  flex: auto;
  overflow: hidden;
  height: calc(60vh - 120px);
  border-radius: $block-radius;
}

.storage__polygon-grid {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 109px);

  .loader--content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.storage__polygon-list {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, #{'minmax(200px, 1fr)'});
  grid-gap: 10px;

  .loader--content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .storage__file,
  .storage__folder {
    min-height: 24px;
    flex-direction: row;
    margin: 0;
    padding: 4px 5px;
    width: 100%;

    .text--eject {
      @include textTruncate(1);
    }

    img,
    svg {
      margin: 0 10px 0 0;
    }

    img {
      min-width: 21px;
      max-width: 21px;
      height: 21px;
    }

    svg {
      min-width: 21px;
      max-width: 21px;
      height: 15px;
    }
  }
}

.storage__to-grid,
.storage__to-list,
.storage__arrow-left {
  //background-color: $events-color;
}

.storage__to-input {
  position: absolute;
  top: 90px;
  left: 185px;
  margin-top: 10px;
  background-color: $layout-color;
  padding: $source-offset;
  border-radius: $block-radius;
  box-shadow: $box-shadow-big;
  z-index: 3;

  .text--eject {
    margin-bottom: 10px;
  }

  .input--eject {
    input {
      padding: 0 45px 0 15px;
    }
  }
}

.storage__to-input__icon {
  position: absolute;
  bottom: 28px;
  right: 30px;
  cursor: pointer;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.storage__to-input__icon--active {
  pointer-events: all;

  --el-fonts--icon-color: #{$success-color};
}

.storage__close {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

.storage__folder,
.storage__file {
  min-height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  flex-direction: column;
  border-radius: $block-radius;
  transition: $move-ease;
  cursor: pointer;
  padding-top: 12px;
  margin: 6px auto;
  width: 95px;

  .text--eject {
    @include textTruncate(2);
  }

  &:hover,
  &.storage__file-active {
    background-color: $layout-color;

    --el-fonts--icon-color: #{$outline-color};

    border-color: $brand-color;
  }

  svg,
  img {
    margin-bottom: 5px;
  }

  path {
    transition: $move-ease;
  }
}

.storage__folder {
  &:hover,
  &.storage__file-active {
    background-color: $layout-color;

    --el-fonts--icon-color: #{$colorEdbee};

    border: 1px solid $colorEdbee;
  }
}

.storage__panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, 237px calc(100% - 247px));
  grid-gap: 20px;
  margin-top: 20px;
  text-align: left;

  @include less {
    grid-gap: 10px;
    margin-top: 10px;
    grid-template-columns: 1fr;
  }

  .button--eject {
    max-height: 44px;
    position: relative;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 0;
      outline: none;
      opacity: 0;
      cursor: pointer;
    }
  }
}

.storage__control-arrow {
  width: 22px;
  height: 14px;
}

.storage__control-dir {
  width: 26px;
  height: 22px;
}

.storage__aside-li {
  cursor: pointer;
  display: flex;
  align-items: center;

  > svg {
    min-width: 8px;
    margin-right: 8px;
    transform: rotate(180deg);
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .storage__aside-ul {
    padding-left: 15px;
    margin-top: 10px;
  }
}

.storage__aside-li--active {
  --el-fonts--text-color: #{$brand-color};
  --el-fonts--icon-color: #{$brand-color};

  > svg {
    transform: rotate(270deg);
  }
}

.storage__aside {
  height: 100%;
  box-shadow: $box-shadow-medium;
  border-radius: $block-radius;
  padding: 15px 11px;
  background-color: $layout-color;
}

.storage__file--image {
  img {
    width: 39px;
    height: 34px;
    object-fit: cover;
    object-position: center;
    background-color: $monochrome-color;
    border-radius: 4px;
  }
}

.storage-context {
  position: fixed;
  z-index: 11;
  padding: 9px 15px;
  background-color: $layout-color;
  border-radius: $block-radius;
  min-width: 150px;
  box-shadow: $box-shadow-big-short;
}

.storage-context__item {
  cursor: pointer;
  padding: 5px 0;

  &:not(:last-child) {
    border-bottom: $border-style;
  }
}

.storage__head-progress {
  width: 50px;
  height: 50px;
  border: $border-style;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -21px;
  right: 50%;
  background-color: $layout-color;
  transform: translateX(50%);
  overflow: hidden;
  z-index: 0;

  div {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0;
    background-color: $brand-color;
    z-index: -1;
  }
}

.storage__select {
  margin-bottom: 10px;
}

.storage__description {
  font-size: 14px;
  color: #d2d7e2;
  font-weight: 500;
  text-align: left;
}

.storage__aside-li-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.storage__folder-icon {
  width: 38px;
  height: 28px;
}

.storage__control-dir--active {
  --el-fonts--icon-color: #{$outline-color};
}

.storage__addition {
  --el-fonts--text-color: #{$label-color};
}
