@import '../../../../assets/scss/shared';

.question-upper {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  .tags {
    --el-fonts--icon-color: #{$brand-color};
  }

  > div {
    margin-bottom: 10px;

    &:not(:last-child, .button--outlined) {
      margin-right: 10px;
    }
  }

  .text--lg {
    display: inline-flex;
    align-items: center;

    svg {
      cursor: pointer;
      margin-left: 8px;
    }
  }

  .question-criteria {
    margin: $source-offset 0;
  }

  .square--active {
    --el-fonts--icon-color: #{$brand-color};
  }
}

.question-upper__add {
  margin-left: auto;
}

.question-upper__add-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

.question-upper__additions {
  position: absolute;
  top: 10px;
  left: calc(100% - 30px);
  z-index: 3;
  background-color: $layout-color;
  padding: 14px 0 5px;
  border: 1px solid $outside-color;
  border-radius: $block-radius;
  box-shadow: $box-shadow-big-short;
  min-width: 170px;

  --el-fonts--icon-color: #{$brand-color};
}

.question-upper__addition {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8.4px 15px;

  &:hover {
    --el-fonts--text-color: #{$brand-color};
  }
}

.question-upper__addition-icon {
  width: 20px;
  min-width: 20px;

  //height: 20px;
  margin-right: 8px;
}
