@mixin reset() {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym,
  address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
  b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead,
  tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav,
  output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    border: 0
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block
  }

  address, caption, cite, code, dfn, em, strong, th, var {
    font-weight: normal;
    font-style: normal;
  }

  caption, th {
    text-align: left;
  }

  ol, ul {
    list-style: none
  }

  table {
    border-collapse: collapse;
    border-spacing: 0
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
}

@mixin vendor($property: '', $value: '') {
  -webkit-#{$property}: $value;
  -khtml-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  #{$property}: $value;
}

@mixin fontFace($path, $name, $family: false, $weight: false) {
  $isFamily: false;
  $isSrc: false;
  $isWeight: false;

  @font-face {
    @if type_of($family) == string {
      $isFamily: $family;
      $isSrc: url('#{$path}/#{$name}.ttf') format('truetype'), /* Safari, Android, iOS */
      url('#{$path}/#{$name}.woff') format('woff'), /* Modern Browsers */
      url('#{$path}/#{$name}.woff2') format('woff2');
      $isWeight: $weight;
    } @else {
      $isFamily: $name;
      $isSrc: url('#{$path}/#{$name}.ttf') format('truetype'),
      url('#{$path}/#{$name}.woff') format('woff');

      //url('#{$path}/#{$name}.svg') format('svg');
      $isWeight: normal;
    }

    font-weight: $isWeight;
    font-family: $isFamily;
    font-style: normal;

    //font-display: block;
    src: $isSrc;

    @content;
  }
}

@mixin typography() {
  button {
    border: 0;
    outline: none;
    cursor: pointer;

    @include vendor(appearance, none);
  }

  button, a {
    &::-moz-focus-inner {
      border: 0;
    }
  }

  svg {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }

  textarea {
    max-width: 100%;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  a:active, a:focus, button::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="file"] > input[type="button"]::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, select::-moz-focus-inner {
    outline: 0
  }

  code, kbd, tt, var, samp, pre {
    font-family: monospace;
    hyphens: none;
  }

  ul,
  ol {
    padding: 0;
    margin: 0;

    > li {
      list-style: none;
    }
  }

  ol:not([class], [id], [style]) {
    list-style-type: decimal;
  }

  table {
    width: 100%;
    line-height: normal;
    table-layout: auto;
    border-width: 0;
    border-spacing: 0;
  }

  @include sm {
    * {
      -webkit-tap-highlight-color: transparent;
    }
  }

  @content;
}

@mixin animate($array: false) {
  @if not  $array == false {
    $set: '';
    $defaultParams: .3s ease-in-out;

    @for $i from 1 through length($array) {
      $set: $set + nth($array, $i) + ' ';
    }

    @if length($array) == 1 {
      animation: #{$set} .3s;
    } @else {
      animation: #{$set};
    }
  }
}

@mixin placeholder($property, $value) {
  &::input-placeholder {
    #{$property}: $value;
  }

  &::placeholder {
    #{$property}: $value;
  }

  &:input-placeholder {
    #{$property}: $value;
  }
}
