@import '../../../assets/scss/shared';

.question-video {
  background-color: $layout-color;
  border-radius: $block-radius;
  box-shadow: $box-shadow-smallest;
  padding: 35px 10px 10px;
}

.question-video__cover,
.question-video__preview {
  position: relative;
  width: 200px;
  height: 112px;
  border-radius: $block-radius;
  overflow: hidden;
}

.question-video__cover-image,
.question-video__stream {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.question-video__icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.question-video__icon--trash {
  top: 10px;
  right: 35px;
}

.question-video__panel-control {
  font-size: 0;
  margin-top: 10px;

  --el-fonts--text-color: #{$label-color};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.question-video__panel-label {
  line-height: 18px;
}
