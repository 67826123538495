@import "../../assets/scss/shared/index";

.settings-form {
  background-color: $mark-color;
  border-radius: $block-radius;
  padding: 20px;
  display: grid;
  grid-gap: 30px;
}

.settings-form__body {
  display: grid;
  grid-gap: 20px;
}

.settings-form__panel {
  display: flex;
  justify-content: flex-end;
}
