@import '../../assets/scss/shared/index';

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 1000px var(--el-ui--image-event-2-color) inset !important;
}

[class*='input--'] {
  display: flex;
  flex-flow: row-reverse wrap;
  position: relative;
  text-align: left;
  align-items: center;

  &:not(.input--t-column) {
    flex-direction: row-reverse;

    @include less {
      flex-direction: column-reverse;
      grid-gap: 10px;

      input {
        flex: auto;
      }
    }

    label + .input__node,
    .input__box-text + .input__node,
    label + .input__view {
      left: 175px;

      @include less {
        left: 15px;
      }
    }
  }

  &.input--b {
    input,
    .input--editable {
      font-weight: 700;
    }
  }

  &:not(.input--b) {
    input,
    .input--editable {
      font-weight: 500;
    }
  }

  &.input--error {
    input,
    .react-tel-input {
      border-color: $error-color;
    }
  }

  input {
    border: $border-style;
    color: $input-color;
    background-color: $input-bg-color;
    box-shadow: $box-shadow-big;
    outline: none;
    width: 100%;
    border-radius: $block-radius;

    @include placeholder(opacity, 0.7);
    @include placeholder(color, $label-color);

    &:focus,
    &:valid {
      ~ .input__node {
        display: none;
      }
    }
  }

  label {
    @include font($text-color 16px 24px 500);

    width: #{'min(160px, 100%)'};
    margin-right: auto;
    padding-right: $default-offset;

    //
    //+ .input__node {
    //  left: 175px;
    //}
  }

  &:not(.input--editable) {
    > span {
      @include font($error-color 12px 21px);

      position: absolute;
      right: 0;
      bottom: 100%;
      transform: translateY(50%);
      background-color: $white-color;
      padding: 0 10px;
      border: 1px solid $error-color;
      border-radius: 4px;
    }
  }

  i {
    position: absolute;
    right: 20px;
    cursor: pointer;

    @include less(min) {
      top: 50%;
      transform: translateY(-50%);
    }

    @include less {
      bottom: 15px;
    }
  }
}

.input__box {
  flex: auto;
  display: flex;
  grid-gap: 20px;
  align-items: center;
}

.input__box-text {
  @include font($text-color 16px 24px 500);
}

.input--t-column {
  flex-direction: column-reverse;

  label {
    font-weight: 700;
    margin-bottom: 7px;

    --el-fonts--text-color: #{$label-color};
  }
}

.input--w-sm {
  max-width: 380px;
}

.input--lg {
  input,
  .input--editable {
    line-height: 55px;
    height: 55px;
    min-height: 55px; //for old v. browsers
    font-size: 16px;
    padding: 0 27px;
  }

  .input__node {
    left: 27px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: 0.7;
  }
}

.input--md {
  input,
  .input--editable {
    line-height: 44px;
    height: 44px;
    min-height: 44px; //for old v. browsers
    font-size: 16px;
    padding: 0 15px;
  }

  &:not(.input--t-column) {
    @include less(min) {
      .input__node {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.input--t-column {
    .input__node {
      bottom: 10px;
    }
  }

  .input__node,
  .input__view {
    left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: 0.7;
  }
}

.input--sm {
  input,
  .input--editable {
    line-height: 38px;
    height: 38px;
    min-height: 38px; //for old v. browsers
    font-size: 14px;
  }

  .input__node {
    left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: $label-color;
    font-weight: 500;
    opacity: 0.7;
  }
}

.input--primary {
  input {
    border: $border-style;
    flex: 1 1 #{'min(290px, calc(100% - 160px))'};
  }
}

.input--secondary {
  input {
    --el-ui--input-bg-color: #{$action-color};

    border: none;
    font-size: 26px;
  }
}

.input--inline {
  padding: 0;

  input,
  .input--editable {
    --el-ui--input-bg-color: transparent;

    height: 22px;
    line-height: 22px;
    min-height: 22px;
    border: none;

    //font-size: 14px;
    box-shadow: none;
    padding: 0;
    outline: none;
  }
}

.input--editable {
  min-width: 20px;
}

.input--slider-price {
  input {
    width: 90px;
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    color: $brand-color;
    padding: 0;
    box-shadow: none;
    font-weight: 700 !important;
    border: 0;
    border-radius: 0;
    height: 39px;
    border-bottom: 1px dashed $brand-color;
    filter: none;
  }
}

.input__node,
.input__view {
  position: absolute;
  pointer-events: none;

  @include less {
    bottom: 10px;
  }
}
