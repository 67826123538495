@import "../../assets/scss/shared/index";

.hint--primary {
  position: fixed;
  background-color: $text-color;
  max-width: 327px;
  padding: 8px 8px 8px 10px;
  border-radius: $block-radius;
  animation: fade .3s ease;
  z-index: 11;

  div {
    --el-fonts--text-color: #{$white-color}
  }
}
